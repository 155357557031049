import { useEffect, useRef, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import ExperienceSection from "./ExperienceSection";
import { experienceSummaryData } from "../data/experienceSummary";
import Button, { ButtonType } from "./layout/Button";
import ButtonLink, { ButonLinkType } from "./layout/ButtonLink";
import { RoutePath } from "../enums/RoutePath.enum";
import { getNavigationPath } from "../functions/navigation-utils";
import useIsTablet from "../hooks/useIsTablet";
import { useTranslation } from "react-i18next";

interface TimeLineItemProps {
    index: number;
    image: string;
    period: string;
    company: string;
    location: string;
    role: string;
    handleClick: (index: number | null) => void;
    activeIndex: number | null
}


const TimeLineItem = ({ index, image, period, company, location, role, handleClick, activeIndex }: TimeLineItemProps) => {

    const {t} = useTranslation();
    
    const [isExpanded, setIsExpanded] = useState(false);

    const isActive = activeIndex !== null && index === activeIndex;

    const isMobile = useIsMobile();
    const isTablet = useIsTablet()

    const elementRef = useRef<HTMLDivElement | null>(null);

    const handleClickCardMobile = () => {

        if ((isMobile || isTablet )) {
            setIsExpanded(prev => !prev);
        }
    }

    const handleClickCardDesktop = () => {
        handleClick(index);
    }

    useEffect(() => {
        if ((isMobile || isTablet ) && activeIndex !== index) {
            setIsExpanded(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])


    return <div ref={elementRef}>
        <li className="mb-4 ms-6">
            <div className="w-full cursor-pointer" onClick={handleClickCardDesktop}>

                <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-gray-100 dark:ring-gray-900 dark:bg-gray-800">
                    <img className="rounded-full border border-gray-300 dark:border-gray-500 shadow-lg grayscale" src={image} alt="company small logo" />
                </span>

                <div className={`w-full text-left p-4 bg-white ${isActive ? 'md:bg-orange-50  focus:bg-white text-gray-900' : 'bg-white text-gray-300 focus:bg-gray-100 '}  border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 md:hover:bg-sky-50 md:dark:hover:bg-sky-800`}>
                    <div className="justify-between md:flex">
                        <div className={`text-base md:text-lg font-normal italic  ${isActive || activeIndex === null ? 'dark:text-gray-200 text-gray-600 ' : 'dark:text-gray-400 text-gray-500 '}`}>
                            {t(period)}
                        </div>
                    </div>
                    <div className={`text-lg md:text-xl font-semibold mb-2 mt-1 ${isActive || activeIndex === null ? 'dark:text-gray-200 text-gray-800 ' : ' dark:text-gray-400 text-gray-500 '}`}>
                        {company}
                    </div>
                    <div className={`text-base md:text-lg  ${isActive || activeIndex === null ? ' dark:text-gray-200 text-gray-600 ' : ' dark:text-gray-400 text-gray-500 '}`}>
                        <div className="flex m mb-1 items-baseline">
                            <span className="mr-2 w-5 h-5 flex items-center justify-center text-light-pastel-blue text-sm">
                                <i className="fa-solid fa-location-dot"></i>
                            </span>
                            {t(location)}
                        </div>
                        <div className="flex text-base md:text-lg items-baseline">
                            <span className="mr-2 w-5 h-5 flex items-center justify-center text-light-pastel-blue text-sm">
                                <i className="fa-solid fa-laptop"></i>
                            </span>
                            {t(role)}
                        </div>
                    </div>

                    {(isMobile || isTablet ) && isExpanded && <div className="mt-4">
                        <ExperienceSection {...experienceSummaryData[index]} />
                    </div>}

                    {(isMobile || isTablet ) && <>

                        {isExpanded && <ButtonLink 
                                to={`/${RoutePath.EXPERIENCE}/${getNavigationPath(activeIndex as number)}`} 
                                type={ButonLinkType.PRIMARY}>{t('global.action.explore_more')}
                            </ButtonLink>}

                        <div className="flex w-full justify-end text-happy-blue">
                            <Button
                                icon={`fas ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                type={ButtonType.PRIMARY}
                                handleClick={handleClickCardMobile}
                            />

                        </div>
                    </>
                    }

                </div>

            </div>
        </li>
    </div>


}

export default TimeLineItem;
import { useEffect, useRef } from "react";
import { HEADER_HEIGHT, RoutePath } from "../enums/RoutePath.enum";
import { getNavigationPath } from "../functions/navigation-utils";
import ExperienceSection from "./ExperienceSection";
import ButtonLink, { ButonLinkType } from "./layout/ButtonLink";
import { H2 } from "./layout/Headings";
import { useTranslation } from "react-i18next";

interface ExperienceCardProps {
    activeIndex: number;
    image: string;
    period: string;
    company: string;
    location: string;
    role: string;
    experienceTasks: string[]
}


const ExperienceCard = (props: ExperienceCardProps) => {
    const { image, company, activeIndex } = props;

    const {t} = useTranslation();

    const cardRef = useRef< HTMLDivElement | null >(null);

    useEffect(() => {
        if (!cardRef.current){
            return;
        }
        const offsetTop = cardRef.current.getBoundingClientRect().top + window.scrollY - HEADER_HEIGHT;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });

    }, [company])

    return <div ref={cardRef} className='p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 items-center justify-center w-full max-h-screen overflow-y-auto overflow-hidden'>
        <div className="flex items-center mb-4">
            <img className="rounded-full border border-gray-400 shadow-lg h-8 w-8" src={image} alt="company logo" />
            <H2 className=" ml-2 ">{company}</H2>
        </div>
        <ExperienceSection {...props} />
        <ButtonLink
            to={`/${RoutePath.EXPERIENCE}/${getNavigationPath(activeIndex)}`}
            type={ButonLinkType.PRIMARY}>
            {t('global.action.explore_more')}
        </ButtonLink>
    </div>
}

export default ExperienceCard;
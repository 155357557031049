import { personalProjectsData } from "../data/personalProjectsData";
import { SectionId } from "../enums/SectionId";
import { getSectionNameById } from "../functions/navigation-utils";
import SectionTitle from "./layout/SectionTitle";
import PersonalProjectCard from "./PersonalProjectCard";

const PersonalProjectsSection = () => {

    return <>

        <SectionTitle title={getSectionNameById(SectionId.PERSONAL_PROJECTS)} />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
            {personalProjectsData.map((project) => (
                <PersonalProjectCard {...project} key={project.projectName} />
            )
            )}
            <div className="relative flex items-center justify-center hidden md:block">
                <img className="absolute w-1/2 opacity-60 inset-0 m-auto grayscale" src="/imgs/illustrations/typing.svg" alt="typing"/>
            </div>

        </div>
    </>
}

export default PersonalProjectsSection;